import React, { FunctionComponent, useContext, Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { AuthContext, AuthState } from '../context/AuthContext';
import './AppShell.css';
import { OrganizationProvider } from '../context/OrganizationProvider';
import { Spinner } from './widgets';

const Login = React.lazy(() => import(/* webpackChunkName: "manager" */'./user/Login'));
const ManagerShell = React.lazy(() => import(/* webpackChunkName: "manager" */'./manager/ManagerShell'));
const PublicShell = React.lazy(() => import(/* webpackChunkName: "public" */'./public/PublicShell'));

const DEV_ORG_KEY = 'referee:dev:org';

const AppShell: FunctionComponent = () => {
  const { state } = useContext(AuthContext);
  const { t, i18n } = useTranslation();

  let organizationId: string;
  if (process.env.NODE_ENV === 'production') {
    [organizationId] = window.location.host.split('.');
  } else {
    const params = new URLSearchParams(window.location.search);
    const orgParam = params.get('dev-org');
    if (orgParam) {
      localStorage.setItem(DEV_ORG_KEY, orgParam);
    }
    organizationId = localStorage.getItem(DEV_ORG_KEY) || '';
  }

  return (
    <OrganizationProvider organizationId={organizationId}>
      <Helmet htmlAttributes={{ lang: i18n.language }} />
      <Suspense fallback={<Spinner message={t('app.loading')} />}>
        <Switch>
          <Route path="/login" component={Login} />
          <Route
            path="/manager"
            render={(props) => {
              if (state === AuthState.AUTHENTICATED) {
                return (
                  <ManagerShell />
                );
              }
              return (
                <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
              );
            }}
          />
          <Route path="/" component={PublicShell} />
        </Switch>
      </Suspense>
    </OrganizationProvider>
  );
};

export default AppShell;

import { ApolloClient, InMemoryCache } from '@apollo/client';
import { setupLinks } from './links';

export default function createApolloClient(graphqlUri: string, region: string) {
  return new ApolloClient({
    link: setupLinks(graphqlUri, region),
    cache: new InMemoryCache({
      typePolicies: {
        Question: {
          keyFields: ['testId', 'id'],
        },
        Answer: {
          keyFields: ['testId', 'questionId', 'id'],
        },
        Query: {
          fields: {
            listTests: {
              keyArgs: ['params'],
              merge(existing, incoming) {
                return {
                  cursor: incoming.cursor,
                  tests: [...existing?.tests || [], ...incoming.tests],
                };
              },
            },
          },
        },
        Session: {
          fields: {
            tests: {
              keyArgs: false,
              merge(existing, incoming) {
                return {
                  cursor: incoming.cursor,
                  tests: [...existing?.tests || [], ...incoming.tests],
                };
              },
            },
          },
        },
      },
    }),
    defaultOptions: {
      query: {
        errorPolicy: 'all',
      },
    },
  });
}

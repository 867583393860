/* eslint-disable no-param-reassign */
import React, { FunctionComponent } from 'react';
import { OrganizationContext } from './OrganizationContext';

interface Props {
  organizationId: string;
}

export const OrganizationProvider: FunctionComponent<Props> = ({
  children,
  organizationId,
}) => (
  <OrganizationContext.Consumer>
    {(context = {
      organizationId,
    }) => {
      if (context.organizationId !== organizationId) {
        context = { ...context, organizationId };
      }

      return (
        <OrganizationContext.Provider value={context}>
          {children}
        </OrganizationContext.Provider>
      );
    }}
  </OrganizationContext.Consumer>
);

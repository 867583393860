import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { Wrapper as ButtonWrapper } from '../button/Button';

interface Props {
  title: string;
}

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;

  ${ButtonWrapper} {
    height: 2.5rem;
    padding: 0.5rem;
    line-height: 1.5rem;
  }
`;

export const ButtonHeader: FunctionComponent<Props> = ({
  title,
  children,
}) => (
  <Wrapper>
    <h1>{title}</h1>
    <div>
      {children}
    </div>
  </Wrapper>
);

import React from 'react';

export enum AuthState {
  AUTHENTICATED,
  NOT_AUTHENTICATED,
  NEW_PASSWORD_REQUIRED
}

interface AuthContextValue {
  state: AuthState;
  setState: (state: AuthState) => void;
}

export const AuthContext = React.createContext<AuthContextValue>({
  state: AuthState.NOT_AUTHENTICATED,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setState: () => {},
});

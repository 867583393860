// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  background-color: #f2f1f3;
}

h1 {
  margin: 0 0 1rem;
}
`, "",{"version":3,"sources":["webpack://./components/AppShell.css"],"names":[],"mappings":"AAAA;EACE,uDAAuD;EACvD,yBAAyB;AAC3B;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":["body {\n  font-family: \"Roboto\", \"Helvetica\", \"Arial\", sans-serif;\n  background-color: #f2f1f3;\n}\n\nh1 {\n  margin: 0 0 1rem;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

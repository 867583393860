/* eslint-disable no-param-reassign */
import React, { FunctionComponent, useEffect, useState } from 'react';
import { Auth } from '@aws-amplify/auth';
import { useTranslation } from 'react-i18next';
import { AuthContext, AuthState } from './AuthContext';
import { Spinner } from '../components/widgets';

export const AuthProvider: FunctionComponent = ({
  children,
}) => {
  const { t } = useTranslation();
  const [state, setState] = useState(AuthState.NOT_AUTHENTICATED);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function loadUser() {
      try {
        const user = await Auth.currentUserInfo();
        if (user) {
          setState(AuthState.AUTHENTICATED);
        }
      } finally {
        setLoading(false);
      }
    }

    loadUser();
  }, []);

  if (loading) {
    return (
      <Spinner message={t('app.loading')} />
    );
  }

  return (
    <AuthContext.Consumer>
      {(context = {
        state,
        setState,
      }) => {
        if (context.state !== state) {
          context = { ...context, state };
        }
        if (context.setState !== setState) {
          context = { ...context, setState };
        }

        return (
          <AuthContext.Provider value={context}>
            {children}
          </AuthContext.Provider>
        );
      }}
    </AuthContext.Consumer>
  );
};

import 'regenerator-runtime/runtime';
import 'whatwg-fetch';
import 'core-js';
import React from 'react';
import { render } from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import LanguageDetector from 'i18next-browser-languagedetector';
import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import { ApolloProvider } from '@apollo/client';
import { Auth } from '@aws-amplify/auth';
import { ThemeProvider } from 'styled-components';
import i18n from './i18n';
import AppShell from './components/AppShell';
import { AuthProvider } from './context/AuthProvider';
import theme from './themes';
import createApolloClient from './apollo';

i18next
  .use(LanguageDetector)
  .use(initReactI18next)
  .init(i18n);

const rootElement = document.getElementById('app');
const userPoolId = process.env.USER_POOL_ID || '';
const identityPoolId = process.env.IDENTITY_POOL_ID || '';
const clientId = process.env.CLIENT_ID || '';
const region = process.env.REGION || '';
const graphqlUri = process.env.GRAPHQL_ENDPOINT || '';

Auth.configure({
  identityPoolId,
  region,
  userPoolId,
  userPoolWebClientId: clientId,
  mandatorySignIn: false,
});

const apolloClient = createApolloClient(graphqlUri, region);

const app = (
  <ThemeProvider theme={theme}>
    <BrowserRouter>
      <AuthProvider>
        <ApolloProvider client={apolloClient}>
          <AppShell />
        </ApolloProvider>
      </AuthProvider>
    </BrowserRouter>
  </ThemeProvider>
);

render(app, rootElement);

import de from './translations/de.json';
import en from './translations/en.json';

const i18n = {
  fallbackLng: 'en',
  debug: process.env.NODE_ENV !== 'production',
  interpolation: {
    escapeValue: false,
  },
  resources: {
    de,
    en,
  },
  whitelist: ['en', 'de'],
};

export default i18n;

import { Theme } from './interface';

const DARK_BLUE1 = '#00263c';
const DARK_BLUE2 = '#063e67';
const BLUE1 = '#2a579c';
const BLUE2 = '#0181c9';
const BLUE3 = '#00aef3';
const GREEN1 = '#088a46';
const GREEN2 = '#48b95e';
const GREEN3 = '#aad357';
const RED1 = '#c01e2e';
const RED2 = '#ec1941';
const RED3 = '#f15257';
const ORANGE1 = '#f78234';
const ORANGE2 = '#f6a125';
const ORANGE3 = '#fdb132';
const WHITE = '#ffffff';
const BLACK = '#000000';
const GREY1 = '#f2f1f3';
const GREY2 = '#e1e1e1';
const GREY3 = '#cccccc';
const GREY4 = '#888888';

const defaultTheme: Theme = {
  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  spinner: {
    main: WHITE,
    color1: GREEN3,
    color2: ORANGE1,
    color3: ORANGE2,
    color4: GREEN2,
    color5: BLUE3,
    color6: BLUE1,
    color7: GREEN1,
    color8: RED1,
    color9: ORANGE3,
    color10: RED3,
    color11: BLUE2,
    color12: RED2,
  },
  button: {
    border: BLACK,
  },
  menu: {
    toggleButton: {
      text: WHITE,
    },
  },
  shellHeaderButton: {
    border: BLUE2,
    text: WHITE,
  },
  list: {
    loadMore: {
      background: WHITE,
      hoverBackground: GREY2,
    },
  },
};

export default defaultTheme;

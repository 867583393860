import React, { ButtonHTMLAttributes, FunctionComponent } from 'react';
import styled from 'styled-components';
import { Spinner, Ball } from '../spinner/Spinner';

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  busy?: boolean;
  busyMessage?: string;
  className?: string;
}

export const Wrapper = styled.button`
  padding: 0.5rem;
  display: flex;
  box-sizing: border-box;
  cursor: pointer;
  border: 1px solid ${({ theme }) => theme.button.border};
`;

const ButtonSpinner = styled(Spinner)`
  padding: 0;

  ${Ball} {
    height: 1rem;
    width: 1rem;
    margin: 0 0.5rem 0 0;
  }

  & span {
    display: none;
  }
`;

export const Button: FunctionComponent<Props> = ({
  busy,
  busyMessage,
  className,
  children,
  ...props
}) => {
  let spinner;
  if (busy) {
    spinner = (<ButtonSpinner message={busyMessage || ''} />);
  }
  return (
    <Wrapper className={className} {...props}>
      {spinner}
      {children}
    </Wrapper>
  );
};

import { createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { Auth } from '@aws-amplify/auth';
import { Signer } from '@aws-amplify/core';
import { print } from 'graphql/language/printer';
import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link';

export function setupLinks(uri: string, region: string) {
  const httpLink = createHttpLink({
    uri,
  });

  const httpAuthLink = setContext(async ({ operationName, variables, query }, { headers }) => {
    try {
      const session = await Auth.currentSession();
      if (session) {
        const token = session.getAccessToken().getJwtToken();

        return {
          headers: {
            ...headers,
            authorization: token || '',
          },
        };
      }
    } catch (err) {
      const {
        accessKeyId,
        secretAccessKey,
        sessionToken,
      } = await Auth.currentCredentials();
      const body = {
        operationName,
        variables,
        query: print(query),
      };

      const request = {
        url: uri,
        data: JSON.stringify(body),
        method: 'POST',
        headers: {
          accept: '*/*',
          'content-type': 'application/json',
          ...headers,
        },
      };

      const result = Signer.sign(
        request,
        { access_key: accessKeyId, secret_key: secretAccessKey, session_token: sessionToken },
        {
          service: 'appsync',
          region,
        },
      );

      return {
        headers: result.headers,
      };
    }
  });

  const httpAuthenticated = httpAuthLink.concat(httpLink);

  return createSubscriptionHandshakeLink({
    url: uri,
    region,
    auth: {
      type: 'AMAZON_COGNITO_USER_POOLS',
      jwtToken: async () => {
        const session = await Auth.currentSession();
        return session.getAccessToken().getJwtToken();
      },
    },
  }, httpAuthenticated);
}
